<template>
    <carousel-wrapper
      :element-key="elementKey"
      :element-data="elementData"
      :display-name="displayname"
      icon-name="icons posts-on-x"
      :children="elementData.postonx" />
  </template>
  
  <script>
  import CarouselWrapper from "@/components/card/CarouselWrapper";
  
  export default {
    name: "PostsOnXBox",
    props: {
      elementKey: String,
      elementData: Object,
    },
    components: {
      CarouselWrapper,
    },
    computed: {
      displayname() {
        return "Posts On X";
      }
    },
  };
  </script>
  
  <scss lang="scss" scoped>
  
  </scss>
  