<template>
  <div class="small-view-result">
    <div v-if="iconName" class="icons" :class="iconName"></div>
    <!-- <favicon v-else-if="url && showFavicons" :url="url" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Favicon from "@/components/Favicon.vue";

export default {
  name: "SmallViewResult",
  props: {
    iconName: String,
    url: {
      type: String,
      required: false,
    },
  },
  components: {
    // Favicon
  },
  computed: {
    ...mapGetters("toolbar", { smallViewFavicons: "smallViewFavicons" }),

    showFavicons() {
      return this.smallViewFavicons;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.small-view-result {
  height: var(--timeline-card-height-small);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
