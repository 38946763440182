<template>
  <carousel-wrapper
    :element-key="elementKey"
    :element-data="elementData"
    :display-name="displayname"
    icon-name="icons what-people-are-saying"
    :children="elementData.whatpeoplearesayingsocial" />
</template>

<script>
import CarouselWrapper from "@/components/card/CarouselWrapper";

export default {
  name: "WhatPeopleAreSayingBox",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    CarouselWrapper,
  },
  computed: {
    displayname() {
      return "What People Are Saying";
    }
  },
};
</script>

<scss lang="scss" scoped>

</scss>
