<template>
  <result-wrapper :element-data="elementData" :icon-name="iconName">
    <template #small-view>
      <small-view-result 
          :icon-name="iconName || flagName" 
          @click="openDialog"
        />
        <ai-overview-dialog :html="elementData.standard.html" @hideDialog="hideDialog" :show="showDialog" />
    </template>
    <div>
      <img class="card-image" :src="AiOverviewLogo" @click="openDialog" />
    </div>
    <ai-overview-dialog :html="elementData.standard.html" @hideDialog="hideDialog" :show="showDialog" />
  </result-wrapper>
</template>

<script>
import ResultWrapper from "@/components/card/ResultWrapper.vue";
import AiOverviewDialog from "@/components/card/AiOverviewDialog.vue";
import { mapGetters } from "vuex";
import SmallViewResult from "../SmallViewResult.vue"

export default {
  name: "AiOverview",
  props: {
    elementData: Object,
  },
  components: {
    ResultWrapper,
    AiOverviewDialog,
    SmallViewResult
  },
  data() {
    return {
      AiOverviewLogo: require('@/assets/images/ai-overview-logo.png'),
      showDialog: false,
    }
  },
  methods: {
    openDialog() {
      if (this.highlightMode) {
        return;
      }
      this.showDialog = true;
    },
    hideDialog() {
      this.showDialog = false;
    },
  },

  computed: {
    ...mapGetters("toolbar", {
      zoomSelection: "zoomSelection",
    }),
    displayName() {
      return 'Ai Overview';
    },
    iconName() {
      if (this.zoomSelection === 'small-view') {
        return 'ai-overview';
      }
      return '';
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card-image {
  width: 70%;
}
</style>
