<template>
  <div>
    <Teleport to="body">
      <dialog-base class="wrapper" :show="isOpenDialog" @hide="hideDialog" :is-draggable="true" :close-on-click="true"
        size="lg" :center="true">
        <template #header>
          <img :src="AiOverviewLogo" />
        </template>

        <template #content>
          <div class="dialog-content" ref="dialogContent" v-html="html"></div>
        </template>
      </dialog-base>
    </Teleport>
  </div>
</template>

<script>
import DialogBase from "@/components/modals/DialogBase.vue";
import { nextTick } from 'vue';

export default {
  components: {
    DialogBase,
  },
  emits: ['hideDialog'],
  data() {
    return {
      AiOverviewLogo: require('@/assets/images/ai-overview-logo.png'),
      dialogContent: null,
      originalDialogHtml: '',
    }
  },
  props: {
    html: {
      type: String,
      required: false,
    },
    show: Boolean,
    isPAA: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  methods: {
    hideDialog() {
      this.showDialog = false;
      this.$emit('hideDialog');
      if (this.dialogContent) {
        this.dialogContent.style.visibility = 'hidden';
        this.dialogContent.innerHTML = this.originalDialogHtml;
      }
      const showMoreButton = document.querySelector('.show-more-button');
      if (showMoreButton) {
        showMoreButton.remove();
      }
      const showLessButton = document.querySelector('.show-less-button');
      if (showLessButton) {
        showLessButton.remove();
      }
    },
    async onDialogShow(newValue) {
      if (newValue) {
        await nextTick();
        this.dialogContent = this.$refs.dialogContent;
        if (this.dialogContent) {
          this.dialogContent.style.visibility = 'visible';
          this.originalDialogHtml = this.dialogContent.innerHTML;
          this.applyPersistentHtmlChanges();
        }
      }
    },

    applyPersistentHtmlChanges() {
      this.applyMinWidth();
      this.handleScroll();
      this.removeTitle();
      this.removeTopPadding();
      this.fixHighlightColor();
      this.removeSvgs();
      this.addShowMoreButton()
      if (!this.isPAA) {
        this.fixGridElements();
      }
      if (this.isPAA) {
        this.fixLinks();
      }

      if (this.isPAA) {
        const button = this.dialogContent.querySelectorAll('*:not(a)');
        const showMore = Array.from(button).filter(el => {
          return getComputedStyle(el).cursor === 'pointer';
        });
        showMore.forEach(el => {
          el.remove();
        })
      }
    },

    applyMinWidth() {
      const firstDiv = this.dialogContent.querySelector('div');
      if (firstDiv) {
        this.isPAA ? firstDiv.style.maxWidth = "600px" : firstDiv.style.minWidth = "60rem";
      }
    },

    handleScroll() {
      const targetDiv = this.dialogContent.querySelector('div');
      if (targetDiv) {
        targetDiv.style.height = "250px";
        targetDiv.style.overflowY = "clip";
      }
    },

    removeTitle() {
      const titles = this.dialogContent.querySelectorAll('h1');
      if (titles) {
        titles.forEach(title =>
          title.remove());
      } else {
        const titles = this.dialogContent.querySelectorAll('strong');
        if (titles) {
          titles.forEach(title =>
            title.remove());
        }
      }

      const headings = [...this.dialogContent.querySelectorAll('div > div[aria-level="2"][role="heading"]')]
        .map(heading => heading.parentElement);
      if (headings) {
        headings.forEach(heading => heading.remove());
      }
    },

    removeTopPadding() {
      const paddingDiv = [...this.dialogContent.querySelectorAll('div')].find(div =>
        div.style.paddingTop.includes("%"));
      if (paddingDiv) {
        paddingDiv.style.paddingTop = "0";
      }
    },

    fixGridElements() {
      let gridElements = this.dialogContent.querySelectorAll('div');
      gridElements = Array.from(gridElements).filter(el => window.getComputedStyle(el).gridColumn.replace(/\s/g, '') === '2/-2');
      if (gridElements.length > 0) {
        gridElements[0].style.gridColumn = "1/-2";
      }
      let makeGridElement = this.dialogContent.querySelector('div[class="UxeQfc"]');
      if (makeGridElement) {
        makeGridElement.style.display = "grid";
        makeGridElement.style.gridTemplateColumns = "minmax(0, 1fr) 428px";
        makeGridElement.style.columnGap = "40px";

      }
    },
    fixHighlightColor() {
      let markedText = this.dialogContent.querySelectorAll('mark');
      if (markedText) {
        markedText.forEach(el => {
          el.style.backgroundColor = '#d3e3fd';
        });
      }
    },

    removeSvgs() {
      let svgs = this.dialogContent ? this.dialogContent.querySelectorAll('svg') : null;
      if (svgs) {
        svgs.forEach(svg => {
          svg.remove();
        });
      }
    },

    fixLinks() {
      const linkContainer = this.dialogContent.querySelector('div[role="list"]');
      if (linkContainer) {
        linkContainer.style.display = "flex";
      }
    },

    addShowMoreButton() {
      const oldShowMoreButton = this.dialogContent.querySelector('div[aria-expanded="false"][role="button"]');
      const showAllButton = this.dialogContent.querySelector('ul li:last-child:has(div[role="button"]):not(:has(a))');
      if (oldShowMoreButton) {
        oldShowMoreButton.remove();
      }
      if (showAllButton) {
        showAllButton.remove();
      }
      let newShowMoreButton = document.createElement("button");
      newShowMoreButton.textContent = "Show More";
      newShowMoreButton.style.display = "block";
      newShowMoreButton.style.width = "50%";
      newShowMoreButton.style.cursor = "pointer";
      newShowMoreButton.style.backgroundColor = "transparent";
      newShowMoreButton.style.border = "1px #a3c9ff solid";
      newShowMoreButton.style.borderRadius = "999rem";
      newShowMoreButton.style.marginTop = "1rem";
      newShowMoreButton.classList.add("show-more-button");
      this.dialogContent.after(newShowMoreButton);

      this.handleShowMoreButtonClick();
    },

    handleShowMoreButtonClick() {
      const showMoreButton = this.dialogContent.parentElement.querySelector('.show-more-button');

      if (showMoreButton) {
        showMoreButton.addEventListener("click", () => {
          showMoreButton.remove();

          this.expandDialogContent();

          let showLessButton = document.createElement("button");
          showLessButton.textContent = "Show Less";
          showLessButton.style.display = "block";
          showLessButton.style.width = "50%";
          showLessButton.style.cursor = "pointer";
          showLessButton.style.backgroundColor = "transparent";
          showLessButton.style.backgroundColor = "transparent";
          showLessButton.style.border = "1px #a3c9ff solid";
          showLessButton.style.borderRadius = "999rem";
          showLessButton.style.marginTop = "1rem";
          showLessButton.classList.add("show-less-button");
          this.dialogContent.after(showLessButton);

          showLessButton.addEventListener("click", () => {
            showLessButton.remove();
            this.dialogContent.innerHTML = this.originalDialogHtml;
            this.applyPersistentHtmlChanges();
          });
        });
      }
    },

    expandDialogContent() {
      const allElements = this.dialogContent.querySelectorAll("*");
      this.setSize();
      this.displayHiddenElements(allElements);
      this.removeBlur(allElements);
      this.setImageSizes();
      if (this.isPAA) {
        this.removePaddingForPaa();
      }
    },
    setSize() {
      const firstDiv = this.dialogContent.querySelector('div');
      firstDiv.style.overflowY = 'visible';
      firstDiv.style.minHeight = '450px';
      if (this.isPAA) {
        firstDiv.style.minWidth = 'fit-content';
      }
    },
    displayHiddenElements(allElements) {
      const hiddenYElements = Array.from(allElements).filter(el =>
        window.getComputedStyle(el).overflowY === "clip"
      );
      hiddenYElements.forEach((el) => {
        el.setAttribute("style", "overflow-y: visible");
      });
    },
    removeBlur(allElements) {
      const blur = Array.from(allElements).filter(el =>
        window.getComputedStyle(el).backgroundImage !== 'none' &&
        window.getComputedStyle(el).backgroundImage.trim().includes('linear-gradient'));
      if (blur.length > 0) {
        blur[0].style.backgroundImage = 'none';
      }
    },
    setImageSizes() {
      let images = Array.from(this.dialogContent.querySelectorAll('img'));
      if (images.length > 0) {
        images.forEach(img => {
          img.style.setProperty("max-width", "80px");
          img.style.setProperty("max-height", "80px");
        })
      }
    },
    removePaddingForPaa() {
      const links = Array.from(this.dialogContent.querySelectorAll('div[role="listitem"]'));
      if (links.length > 0) {
        links.forEach(link => {
          const paddedDivs = Array.from(link.querySelectorAll('div')).filter(div =>
            div.style.paddingTop.includes("%")
          );
          if (paddedDivs.length > 0) {
            paddedDivs.forEach(paddedDiv => {
              paddedDiv.style.paddingTop = "0";
            })
          }
        })
      }
    },
  },
  computed: {
    isOpenDialog() {
      return this.show;
    },
  },
  watch: {
    isOpenDialog(newValue) {
      if (newValue) {
        this.onDialogShow(newValue);
      }
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.dialog-content {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>