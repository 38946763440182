<template>

  <template v-if="zoomSelection === 'small-view' && birdseyeAlwaysOpen">
    <highlight-result :class="[zoomSelection, 'card', getMarkupClasses(item)]" :element-data="elementData"
                      :allowHighlight="!isOpen" v-for="(item, index) in elementData.computed?.containerDetailSections"
                      :key="elementKey + index">
      <small-view-result :icon-name="iconName" />
    </highlight-result>
  </template>

  <template v-else-if="zoomSelection === 'small-view'">
    <highlight-result :element-data="elementData" :allowHighlight="!isOpen">
      <small-view-result @click.stop="toggleFocusedView()" :icon-name="iconName" />
    </highlight-result>
  </template>


  <template v-else>
    <highlight-result :element-data="elementData" :allowHighlight="!isOpen"
                      :class="{ 'is-open': isOpen, 'is-scrollable': isScrollable }">
      <div class="card-body d-flex flex-column justify-content-center align-items-center">
        <div v-if="!alwaysOpen"
             class="result-container d-flex flex-column align-items-center w-100 justify-content-between"
             :href="'#' + elementKey"
             aria-expanded="false"
             aria-controls="elementKey"
             >
          <div class="carousel-container">
            <div class="card-title-container">
              <span v-if="iconName"
                      class="icons"
                      :class="[iconName, { clickable: focusedViewSupported }]"
                      @click.stop="toggleFocusedView()">
                </span>
                <span class="card-title m-auto"
                      :class="{ ellipsis: isOpen }"
                      v-html="displayName">
                </span>
                <div class="arrow-icon">
                  <i v-if="canSubresultsOpen && isFocused" @click.stop="toggleOpenAll()"
                    class="bi bi-chevron-double-down rotate text-primary" :class="{ up: isAllOpen }" />
                  <i v-if="!isFocused" @click.stop="toggleOpen()" class="bi bi-chevron-down rotate text-primary"
                    :class="{ up: isOpen }" />
                </div>
            </div>
            <template v-if="!isOpen">
              <span class="carousel-images-box">
                <div v-for="image in resultTypeImages" :key="image.desc" class="single-image-container">
                  <img 
                    class="highlighted-image carousel-image" :class="getMarkupClasses(image)" :src="image.desc ? image.desc : getSocialMediaLogo(image)"
                    :alt="image.alt || ''" >
                  <!-- <favicon v-if="zoomSelection === 'regular-view'" :key="image.desc" :size="10" :url="image.url" :position="'relative'" class="favicon-image"/> -->
                </div>
              </span>
            </template>
          </div>
        </div>

        <i v-if="alwaysOpen" class="flag icons" :class="iconName"></i>

        <div class="collapse w-100" :class="{ show: isOpen }" :id="elementKey">
          <div class="accordion " :class="{ condensed }" :id="elementKey + '_acrdn'">
            <slot :accordionKey="elementKey + '_acrdn'"> 

              <div v-for="(child, index) in children" :key="index"
              class="accordion-item"
              >
                <highlight-result :element-data="child" :class="getMarkupClasses(child)" class="accordian-item-text">
                  <div
                  class="accordion-header markup-container markup-container-with-image"
                  :id="'heading' + index"
                  >
                    <div class="outer-image-wrapper">
                      <div class="image-wrapper">
                        <img :src="child.desc ? child.desc : getSocialMediaLogo(child)" />
                      </div>
                    </div>
                    <div>
                      <span class="card-title post-title ellipsis" v-html="this.alterTitle(child.title)" />
                      <markup-link
                        :resultData="child"
                        :datetime="elementData.card.datetime"
                        :resultType="child.resulttype"
                      />
                    </div>
                  </div>
                </highlight-result>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </highlight-result>
  </template>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import HighlightResult from "@/components/card/HighlightResult.vue";
import MarkupLink from "@/components/card/MarkupLink.vue";
import SmallViewResult from "@/components/card/SmallViewResult.vue";
// import Favicon from "@/components/Favicon.vue";
import commonUtil from "@/utils/commonUtil";
import { socialMediaLogos } from "@/carousel-logos.js";

export default {
  name: "CarouselWrapper",
  emits: ["focusViewClick"],
  props: {
    elementKey: {
      type: String,
      required: true,
    },
    elementData: {
      type: Object,
      required: true,
    },
    displayName: String,
    iconName: String,
    alwaysOpen: Boolean,
    birdseyeAlwaysOpen: {
      type: Boolean,
      default: false,
    },
    condensed: Boolean,
    showImagesOnClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    // for now only PAA has subresults that can open. If another type eventually gets this, move toggling children logic here
    canSubresultsOpen: Boolean,
    isAnySubresultOpen: Boolean,
    children: {
      type: Array,
      required: false,
    }
  },

  components: {
    HighlightResult,
    SmallViewResult,
    // Favicon,
    MarkupLink
  },
  data() {
    return {
      defaultImage: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 630'%3E%3C!-- Background --%3E%3Crect width='1200' height='630' fill='%23f5f5f5'/%3E%3C!-- Decorative elements --%3E%3Cpath d='M0 0 L1200 0 L1200 30 Q600 60 0 30 Z' fill='%23e0e0e0'/%3E%3Cpath d='M0 630 L1200 630 L1200 600 Q600 570 0 600 Z' fill='%23e0e0e0'/%3E%3C!-- Image placeholder icon --%3E%3Crect x='500' y='215' width='200' height='150' rx='10' fill='none' stroke='%23909090' stroke-width='8'/%3E%3Cpath d='M520 335 L570 285 L620 335' fill='none' stroke='%23909090' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='650' cy='255' r='15' fill='%23909090'/%3E%3C!-- Text placeholder lines --%3E%3Crect x='400' y='400' width='400' height='20' rx='5' fill='%23d0d0d0'/%3E%3Crect x='450' y='440' width='300' height='20' rx='5' fill='%23d0d0d0'/%3E%3C/svg%3E",
    }
  },

  computed: {
    ...mapGetters("timeline", {
      isResultOpen: "isResultOpen",
      isSubResultsOpen: "isSubResultsOpen",
      focusedElement: "focusedElement",
      paaOnlyViewFromLegacy: "paaOnlyViewFromLegacy",
    }),
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),
    ...mapGetters("routeParams", { view: "view" }),
    resultId() {
      return this.elementData.resulttype;
    },
    isOpen() {
      return this.alwaysOpen || this.isFocused || this.isResultOpen(this.resultId);
    },
    isFocused() {
      return this.focusedElement === this.resultId;
    },
    isScrollable() {
      const maxRows = this.condensed ? 8 : 4; // keep in sync with the timeline-container-scrolling-heights in sizes.scss
      return !this.isFocused && !this.isAnySubresultOpen && this.elementData.computed.containerDetailSections?.length > maxRows;
    },

    focusedViewSupported() {
      return this.view === "timeline" && !this.paaOnlyViewFromLegacy;
    },
    isAllOpen() {
      return this.isSubResultsOpen(this.resultId);
    },
    resultTypeImages() {
      const typeMapping = {
        imagecontainer: 'imagecontainer.image',
        shortvideoscontainer: 'shortvideo',
        latestpostsbox: 'post',
        perspectivesbox: 'perspective',
        whatpeoplearesayingbox: 'whatpeoplearesayingsocial',
        postsonxbox: 'postonx',
      };
      const keyPath = typeMapping[this.elementData.resulttype];
      let images = keyPath ? this.elementData[keyPath] : [];
      return images.slice(0, 4);
    },
  },
  methods: {
    ...mapActions("timeline", ["toggleResultOpen", "toggleSubResultsOpen", "setFocusedElement"]),
    ...mapActions("toolbar", ["setZoomSelection"]),

    toggleOpen() {
      if (this.isFocused) {
        this.toggleOpenAll();
      } else {
        this.toggleResultOpen(this.resultId);
      }
    },
    toggleOpenAll() {
      if (this.canSubresultsOpen) {
        this.toggleSubResultsOpen(this.resultId);
        if (!this.isOpen && this.isAllOpen) {
          this.toggleResultOpen(this.resultId);
        }
      }
    },
    toggleFocusedView() {
      if (!this.focusedViewSupported) {
        return;
      }

      this.setFocusedElement(this.isFocused ? "" : this.resultId);
      this.$emit('focusViewClick');
      if (this.isAllOpen && !this.isFocused) {
        this.toggleSubResultsOpen(this.resultId);
      }
      if (this.isFocused) {
        this.toggleOpenAll();
      }
    },
    getSocialMediaLogo(image) {
      const domain = commonUtil.extractDomain(image.url);
      const matchingKey = Object.keys(socialMediaLogos).find(key => domain.includes(key));
      return matchingKey ? socialMediaLogos[matchingKey] : this.defaultImage;
    },
    alterTitle(title) {
      if (title?.includes("~")) {
        return title.replace("~", " - ");
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.is-open:not(.small-view) {
  border: solid 1px var(--timeline-result-separator-color);

  .result-container {
    background-color: var(--timeline-card-container-header-bg);
    height: var(--carousel-container-title-regular);
    flex-direction: row !important;

    .arrow-icon {
      bottom: initial;
    }
  }
  .medium-view .result-container {
    height: var(--carousel-container-title-medium);
  }
}

*,
:slotted(*) {
  &.small-view {
    .card {
      width: var(--timeline-card-width-small);
      margin: var(--timeline-result-margin-small);
    }
  }
}

.regular-view,
.medium-view {
  border-radius: var(--border-radius-timeline-container);

  .card-title-container {
    display: flex;
    align-items: center;
    max-height: 100%;

    span { 
      width: 100%;
    }
  }

  .result-container {
    padding-left: 0.3em;
    transition: var(--transition-zoom-selection), var(--transition-open-result);
  }

  .carousel-images-box {
    display: flex;
    justify-content: space-around;
    .single-image-container{
      max-width: 20%;
      display: inline-block;
      position: relative;
      overflow: hidden;
      aspect-ratio: 1/1;
    }
  }

  .collapse {
    transition: all 0.5s ease;

    &:not(.show) {
      display: inherit;
      height: 0px;
      overflow: hidden;
      opacity: 0;
    }
  }

  .rotate {
    line-height: 0px;
    display: inline-block;
    @include hover-clickable-effect;

    &.up {
      transform: rotate(-180deg);
      transition: transform 0.2s ease-in-out;
      @include hover-clickable-effect(rotate(-180deg));
    }
  }

  *,
  :slotted(*) {
    .card-title {
      color: var(--fb-blue-70);
      font-weight: 500;
      margin: 0;
    }

    .ellipsis {
      @include ellipsis;
    }

    .card-body {
      padding: 0;
      border-radius: 0.25rem;
    }
  }

  .icons {
    margin-right: 0.5rem;
  }

  .flag {
    position: absolute;
    top: 2px;
    right: -5px;
  }

}

.regular-view {
  .carousel-images-box {
        max-height: var(--timeline-result-height-regular);
      }
  img {
      width: 100%;
      height: 95%;
      object-fit: contain;
      }
  .favicon {
    bottom: 15px;
    left: 5px;
  }
}

.medium-view {
  .carousel-images-box {
        height: var(--timeline-result-height-medium);
      }
 img {
   width: 100%;
   height: 90%;
   object-fit: contain;
 }
  img:last-of-type {
    margin-right: 2px;
  }

  //DM-4956- .highlight-element in HighlightResult.vue has the css overflow-x: hidden (DM-3717). Removing that solves the bug with the scrollbar here.
  //However, I was afraid it would impact other parts of the code. Instead, I added a new class here that's specific to this file. 
  .accordian-item-text { 
    overflow: hidden; 
  }
}
  


@mixin zoomViewSizes($type) {

  .#{$type}-view {
    .result-container {
      height: var(--timeline-carousel-height-#{$type});
      overflow: hidden;
      .carousel-container {
        display: flex;
        flex-direction: column;
        max-height: var(--timeline-carousel-height-#{$type});
        width: 100%;

          .card-title-container {
            padding-left: 2px;
            background-color: var(--timeline-card-container-header-bg);
            .icons {
              margin-top: 0;
              padding-right: 0.5rem;
          }
        }
      .card-title {
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-title-container span :deep(img) {
      border: solid var(--highlight-image-outline-width-#{$type});
      border-color: white;
    }

    .carousel-image {
        border: solid var(--highlight-image-outline-width-#{$type});
        border-color: white;
        margin-top: .15rem;
    }

    .card-title.ellipsis {
      width: var(--result-text-width-container-title-icon-#{$type});
    }
    .arrow-icon {
      margin-left: auto;
      position: relative;
      }
      .rotate {
        font-size: var(--timeline-accordion-toggle-font-size-#{$type});
        margin-right: calc(var(--timeline-accordion-toggle-right-offset-#{$type}) + 1px);
      }
    .outer-image-wrapper {
      overflow: hidden;
    }
    .image-wrapper {
      width: 68px;
      height: var(--timeline-result-height-regular);
      margin: auto;
      display: flex;
      justify-content: center;
    }
  }
    .adjust-for-kp {
      width: calc(var(--result-text-width-kp-adjust-#{$type}) + var(--timeline-result-width-padding));
      transition: var(--transition-zoom-selection);

      :slotted(*) {
        .ellipsis {
          max-width: var(--result-text-width-kp-adjust-#{$type});
        }

        .markup-container-with-image .ellipsis {
          max-width: var(--result-text-width-kp-adjust-image-adjust-#{$type});
        }
      }
    }

    *,
    :slotted(*) {
      &>* {
        font-size: var(--timeline-font-size-#{$type});
      }

      .markup-container-with-image .ellipsis {
        max-width: var(--result-text-width-image-adjust-#{$type});
      }

      &.accordion-item {
        border-radius: var(--border-radius-timeline-result);
        border: none;

        &>*,
        &:last-of-type>* {
          border-radius: var(--border-radius-timeline-result);
        }
      }
    }

    .icons.clickable,
    .bi-chevron-down,
    .bi-chevron-double-down {
      @include hover-clickable-effect;
    }

    .icons:not(.flag) {
      width: var(--result-container-title-icon-width-#{$type});
      font-size: var(--result-container-title-icon-width-#{$type});
    }

    @if $type =="medium" {
      .icons:not(.flag) {
        margin-right: 0.3rem;
      }

      .flag {
        width: var(--result-container-title-flag-width-medium);
        height: var(--result-container-title-flag-width-medium);
        top: 2px;
        right: -5px;
      }
    }

    .is-scrollable {
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: none;

      &:hover {
        scrollbar-width: thin;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &:hover {
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #aaa;
        }
      }

      max-height: var(--timeline-container-scrolling-height-#{$type});
    }
  }
}



@include zoomViewSizes("regular");
@include zoomViewSizes("medium");
</style>
