import { createWebHistory, createRouter } from "vue-router";
import { pathOf } from "@/composables/useRouteHelper";

const routes = [
  {
    path: "/:pathMatch(.*)",
    redirect: { name: "Timeline", params: {} }
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: () => import("@/views/Timeline.vue"),
  },
  {
    path: "/timeline-news",
    name: "News",
    component: () => import("@/views/Timeline.vue"),
  },
  {
    path: "/timelinePAA",
    name: "TimelinePAA",
    component: () => import("@/views/Timeline.vue"),
    props: { source: 'serp', isPaaOnlyViewFromLegacy: true },
  },
  {
    path: "/timelinecompare",
    name: "Timeline Compare",
    component: () => import("@/views/TimelineCompare.vue"),
  },
  {
    path: "/timelineKP",
    name: "Knowledge Panel",
    component: () => import("@/views/TimelineKP.vue"),
  },
  {
    path: "/iresults",
    name: "iResults Manager",
    component: () => import("@/views/IResultsManager.vue"),
  },
  {
    path: "/keywordSummary",
    name: "Keyword Summary",
    component: () => import("@/views/KeywordSummary.vue"),
  },
//  {   To be added at a later time
  //   path: "/clients",
  //   name: "Clients Manager",
  //   component: () => import("@/views/ClientManager.vue"),
  // },
  {
    path: "/keywords",
    name: "Keywords Manager",
    component: () => import("@/views/KeywordManager.vue"),
  },
  {
    path: "/notificationsManagement",
    name: "Notifications Management Page",
    component: () => import("@/views/NotificationsManagement.vue"),
  },
  {
    path: "/notificationReports",
    name: "Notification Reports",
    component: () => import("@/views/NotificationReports.vue"),
  },
  {
    path: "/parsing-tester",
    name: "Home",
    component: () => import("@/views/parsing-tester"),
  },
  {
    path: "/build-compare",
    name: "Comparison Report",
    component: () => import("@/views/parsing-tester/BuildCompare.vue"),
  },
  {
    path: "/single-parser",
    name: "Single Parser Report",
    component: () => import("@/views/parsing-tester/SingleParser.vue"),
  },
  {
    path: "/PDiffMain",
    name: "Main Report",
    component: () => import("@/views/parsing-tester/PDiffMain.vue"),
  },
  {
    path: "/pdiff-element-report",
    name: "Element Report",
    component: () => import("@/views/parsing-tester/PDiffElement.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
  path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
  path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
];

routes.forEach(route => route.path = pathOf(route.path));

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;