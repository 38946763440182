import api from '@/api';

const state = () => ({
    allTermsForClient: [],
    newsLocations: [],
    allCountries: [],
    editingIndex: null,
    showModal: false,
});


const getters = {
    allTermsForClient: state => state.allTermsForClient,
    newsLocations: state => state.newsLocations,
    allCountries: state => state.allCountries,
    editingIndex: state => state.editingIndex,
    showModal: state => state.showModal,
};

const actions = {
    setMyClients({ commit, state }, { userId }) {
        return new Promise((resolve, reject) => {
            api.getMyClients(userId)
                .then((response) => {
                    commit('SET_MY_CLIENTS', response.data);
                    resolve(state.myClients);
                }).catch((error) => {
                    commit('SET_MY_CLIENTS', []);
                    console.error(error);
                    reject(error);
                });
        });
    },
    setAllTermsForClient({ commit }, payload) {
        commit('SET_ALL_TERMS_FOR_CLIENT', payload);
    },
    addToNewsLocations({ commit }, payload) {
        commit('ADD_TO_NEWS_LOCATIONS', payload)
    },
    removeFromNewsLocations({ commit }, payload) {
        commit('REMOVE_FROM_NEWS_LOCATIONS', payload)
    },
    removeAllForTermFromNewsLocations({ commit }, payload) {
        commit('REMOVE_ALL_FOR_TERM_FROM_NEWS_LOCATIONS', payload)
    },
    resetNewsLocations({ commit }, payload) {
        commit('RESET_NEWS_LOCATIONS', payload)
    },
    setAllCountries({ commit }, payload) {
        commit('SET_ALL_COUNTRIES', payload)
    },
    setEditingIndex({ commit }, payload) {
        commit('SET_EDITING_INDEX', payload);
    },
    setShowModal({ commit }, payload) {
        commit('SET_SHOW_MODAL', payload);
    },
}

const mutations = {
    SET_MY_CLIENTS(state, payload) {
        state.myClients = payload;
    },
    SET_ALL_TERMS_FOR_CLIENT(state, payload) {
        state.allTermsForClient = payload;
    },
    ADD_TO_NEWS_LOCATIONS(state, payload) { 
        payload?.forEach(item => {
            if (!state.newsLocations.some(loc =>
                loc.term === item.term && loc.location === item.location)) {
                state.newsLocations = [...state.newsLocations, ...payload];
            }
        })
    },
    REMOVE_FROM_NEWS_LOCATIONS(state, payload) { 
        const { term, location, parentTerm } = payload;
            if (!parentTerm) {
                state.newsLocations = state.newsLocations.filter(
                    (loc) =>
                        !(loc.term === term && loc.location === location)
                );
            } else {
                state.newsLocations = state.newsLocations.filter(
                    (loc) =>
                        !(loc.term === term && loc.parentTerm === parentTerm)
                );
            }
    },
    REMOVE_ALL_FOR_TERM_FROM_NEWS_LOCATIONS(state, payload ) { 
        state.newsLocations = state.newsLocations.filter(
            (loc) =>
                !(loc.term === payload)
        );
    },
    RESET_NEWS_LOCATIONS(state) {
        state.newsLocations = [];
    },
    SET_ALL_COUNTRIES(state, payload) {
        state.allCountries = payload;
    },
    SET_EDITING_INDEX(state, payload) {
        state.editingIndex = payload;
    },
    SET_SHOW_MODAL(state, payload) {
        state.showModal = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
