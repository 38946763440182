<template>
  <div>
    <container-wrapper v-slot="slotProps" :element-key="elementKey" :element-data="elementData"
      display-name="People also ask" icon-name="bi bi-question-square" :condensed="true" :can-subresults-open="true"
      :is-any-subresult-open="anyQuestionOpen">
      <div v-for="(question, index) in elementData.question" :id="'highlight_key_' + elementKey + question.titleId"
        :key="slotProps.accordionKey + index" class="accordion-item paa-border" :data-titleid="question.titleId"
        :data-urlid="question.urlid" :data-domainid="question.domainId" :data-descid="question.descId">
        <highlight-result :element-data="question" :class="getMarkupClasses(question)" class="accordion-header">
          <div
            class="question-container result-container d-flex align-items-center w-100 justify-content-between position-relative"
            :class="{ collapsed: !this.isOpen(question) }" :title="question.title" @click.stop="toggleOpen(question)">
            <div class="markup-container">
              <span class="question " :class="{ ellipsis: !this.isOpen(question) }">{{ question.title }}</span>
            </div>
            <i class="bi bi-chevron-down rotate text-primary" :class="{ up: isOpen(question) }" />
          </div>
        </highlight-result>
        <div :id="'key_' + elementKey + question.titleId" class="accordion-collapse collapse"
          :class="[getMarkupClasses(question), { show: this.isOpen(question) }]">
          <div class="accordion-body">
            <div class="markup-container">
              <div class="d-flex flex-column qa-wrapper pointer">
                <highlight-result :type-id="'desc' + question.descId" :element-data="elementData">
                  <span v-if="isAiOverview(question.desc)" class="ai-overview-container"
                    @click="handleDescriptionClick(index)">
                    <i class="icons ai-overview ai-overview-icon"></i>
                    <span class="ai-overview-text"> {{ computedDesc(question.desc) }}</span>
                  </span>
                  <span v-else> {{ computedDesc(question.desc) }} </span>
                </highlight-result>
                <highlight-result :element-data="question">
                  <markup-link :resultData="question" :datetime="elementData.card.datetime"
                    :resultType="question.resulttype" />
                </highlight-result>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container-wrapper>
    <ai-overview-dialog :html="elementData.question[indexOfHtmlToShow].html" @hideDialog="hideDialog" :show="showDialog" :isPAA="true" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContainerWrapper from "@/components/card/ContainerWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import AiOverviewDialog from "@/components/card/AiOverviewDialog.vue";

export default {
  name: "QuestionAnswerBox",
  props: {
    elementData: Object,
    elementKey: String,
  },
  data() {
    return {
      showDialog: false,
      indexOfHtmlToShow: 0, //really, this should be null, but the way the DialogBase component works is that it can't be conditionally rendered, so this needs to be a real value
    }
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
    AiOverviewDialog,
  },
  computed: {
    ...mapGetters("timeline", {
      isResultOpen: "isResultOpen",
    }),
    ...mapGetters("highlight", { highlightMode: "highlightMode" }),
    anyQuestionOpen() {
      return this.elementData.question.some(question => this.isOpen(question));
    },
    computedDesc() {
      return (desc) => {
        if (!this.isAiOverview) {
        return desc;
        }
        return desc.replace(/^AI Overview\s*/, "");
      }
    }
  },
  methods: {
    ...mapActions("timeline", ["toggleResultOpen", "initClosedResults"]),
    titleKey(question) {
      return this.elementData.resulttype + question.titleId;
    },
    isOpen(question) {
      return this.isResultOpen(this.titleKey(question));
    },
    toggleOpen(question) {
      this.toggleResultOpen(this.titleKey(question));
    },
    isAiOverview(answer) {
      return answer?.toLowerCase().includes('ai overview');
    },
    handleDescriptionClick(index) {
      if (!this.isAiOverview(this.elementData.question[index]?.desc) || !this.elementData.question[index].html || this.hightlightMode || this.showDialog) {
        return;
      } else {
        this.showDialog = true;
        this.indexOfHtmlToShow = index;
      }
    },
    hideDialog() {
      this.showDialog = false;
    },

  },
  mounted() {
    const ids = this.elementData.question.map((q) => this.titleKey(q));
    this.initClosedResults(ids);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";


@mixin zoomViewSizes($type) {
  .#{$type}-view {
    .rotate {
      font-size: var(--timeline-accordion-toggle-font-size-#{$type});
      margin-right: calc(var(--timeline-accordion-toggle-right-offset-#{$type}) - 1px);
      align-self: baseline;
    }

    .question-container .ellipsis {
      max-width: var(--result-text-width-container-title-#{$type});
    }
  }
}

@include zoomViewSizes("regular");
@include zoomViewSizes("medium");

.card {
  .question-container {
    cursor: default;
    overflow: hidden;

    .rotate {
      line-height: 0px;
      display: inline-block;
      @include hover-clickable-effect;

      &.up {
        transform: rotate(-180deg);
        transition: transform 0.2s ease-in-out;
        @include hover-clickable-effect(rotate(-180deg));
      }
    }

    .markup-container {
      .question {
        color: var(--fb-blue-70);
      }
    }
  }

  .accordion-collapse {
    overflow: hidden;
    text-overflow: ellipsis;

    .accordion-body {
      padding: 0 0.05rem;

      .qa-wrapper {
        max-width: 245px;
        overflow: auto;

        &>* {
          padding: 0.25rem 0;
        }

        .ai-overview-container {
          display: flex;
          align-items: flex-start;
          cursor: pointer;

          .ai-overview-icon {
            flex-shrink: 0;
            font-size: var(--timeline-font-size-regular);
          }
          .ai-overview-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .markup-container {
        opacity: 0.9;
        color: var(--fb-blue-70);
      }
    }
  }
}
</style>
