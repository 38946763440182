<template>
  <div ref="modal" class="modal fade" :class="{ 'is-modal': isModal }" @keyup.esc="handleEsc" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" :class="'modal-' + size" ref="dialog">
      <div class="modal-content">
        <div class="modal-body p-0">

          <div class="draggable-container" :class="{ styled }" ref="draggableContainer"
            v-click-outside="handleClickOutside">
            <div class="draggable-header" :class="{ 'is-draggable': isDraggable }" ref="dragHandle"
              @mousedown="dragMouseDown">

              <button v-if="showX" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                @click="handleClickClose" />
              <slot name="header"></slot>
            </div>

            <div class="content">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import vClickOutside from "click-outside-vue3";

export default {
  name: 'DialogBase',
  emits: ["hide"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data: function () {
    return {
      modal: null,
      pad: 40,
      // so the trigger that opens the dialog doesn't also close it
      temporarilyDisableCloseOnClick: false,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    styled: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: String,
      required: false,
      default: 'sm', // sm, md, lg
    },
    closeOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    showX: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    draggableContainer() {
      return this.$refs.draggableContainer;
    },
    dragHandle() {
      return this.$refs.dragHandle;
    },
    isModal() {
      return !this.isDraggable;
    },
    isCloseOnClickActive() {
      return this.closeOnClick && !this.temporarilyDisableCloseOnClick;
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);

    if (!this.isModal) {
      // adjust for having hid the background by moving it far to the left in css
      this.draggableContainer.style.left = 10000 + this.draggableContainer.offsetLeft + 'px';
    }
  },
  watch: {
    show(value) {
      if (value && this.isCloseOnClickActive) {
        this.temporarilyDisableCloseOnClick = true;
        window.setTimeout(() => this.temporarilyDisableCloseOnClick = false, 500);
      }

      value ? this.modal.show() : this.modal.hide();
    },
  },
  methods: {
    dragMouseDown: function (event) {
      if (!this.isDraggable) {
        return;
      }

      event.preventDefault()
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },

    elementDrag: function (event) {
      if (!this.isDraggable) {
        return;
      }

      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      this.draggableContainer.style.top = (this.draggableContainer.offsetTop - this.positions.movementY) + 'px';
      this.draggableContainer.style.left = (this.draggableContainer.offsetLeft - this.positions.movementX) + 'px';
    },

    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.keepDragHandleWithinFrame();
    },

    keepDragHandleWithinFrame() {
      const headerBounds = this.dragHandle.getBoundingClientRect();

      const windowLeft = this.pad;
      const windowRight = window.innerWidth - this.pad;
      const windowTop = this.pad;
      const windowBottom = window.innerHeight - this.pad;

      const left = headerBounds.left;
      const right = headerBounds.right;
      const top = headerBounds.top;
      const bottom = headerBounds.bottom;

      const fixX = right < windowLeft ? windowLeft - right : left > windowRight ? windowRight - left : null;
      const fixY = bottom < windowTop ? windowTop - bottom : top > windowBottom ? windowBottom - top : null;

      if (fixX != null) {
        this.draggableContainer.style.left = (this.draggableContainer.offsetLeft + fixX) + 'px';
      }
      if (fixY != null) {
        this.draggableContainer.style.top = (this.draggableContainer.offsetTop + fixY) + 'px';
      }
    },
    handleClickOutside(event) {
      if (this.isCloseOnClickActive && this.modal._isShown) {
        this.$emit('hide', event);
      }
    },
    handleClickClose() {
      this.$emit('hide');
    },
    handleEsc() {
      this.$emit('hide');
    },
  }
}
</script>

<style lang="scss" scoped>
.draggable-container {
  position: absolute;
  z-index: var(--z-popup);
  box-shadow: 0 0 30px var(--popup-shadow-color);
  background: white;
  border: solid 1px rgba(0, 0, 0, .2);
  border-radius: var(--border-radius-popup);
  min-width: 23rem;
  min-height: 12rem;

  .draggable-header {
    z-index: calc(var(--z-popup) + var(--z-raise));
    border-radius: var(--border-radius-popup) var(--border-radius-popup) 0 0;

    &.is-draggable {
      cursor: move;
    }
  }

  &.styled {

    .draggable-header {
      font-weight: bold;
      color: var(--fb-blue-70);
      margin: 1rem;
    }

    .content {
      margin: 1rem;
    }
  }

  &:not(.styled).draggable-header {
    border: 0;
  }
}


button {
  font-size: 13px;
  position: absolute;
  right: 7px;
  z-index: var(--z-raise);
  top: 10px;
}

.modal {
  overflow: visible;
  z-index: var(--z-popup);

  &:not(.is-modal) {
    position: absolute;
    left: -10000px;
  }
}

.modal-md .draggable-container {
  top: 8rem;
}

.modal-sm .draggable-container {
  top: 13rem;
}
</style>
