import { ViewStates } from "@/components/app-header/timeline-toolbar/views/ViewStates.model";
import storage from "@/utils/storageUtil";

const state = () => ({
    term: {},
    viewStates: new ViewStates(),
    caseSensitive: false,
    resolution: {
        days: 1,
        columns: 0,
        custom: false,
        customOpen: false,
    },
    zoomSelection: 'regular-view',
    selectedPages: [1],
    toolsSelection: "",
    actionTimelineCopy: 0,
    actionTimelineExport: 0,
    hideCards: true,

    pagesDisabled: false,
    resolutionDisabled: false,
    zoomDisabled: 'none', // all, none, small
    csvDownloadDisabled: false,
    viewMenuDisabled: false,
    markupDisabled: [],

    copyOrExportCompleted: 0,
    leftScrollBeforeCopyOrExport: null, 

    smallViewFavicons: true
});

const getters = {
    resolution: state => state.resolution,
    term: state => state.term,
    viewStates: state => state.viewStates,
    zoomSelection: state => state.zoomSelection,
    selectedPages: state => state.selectedPages,
    toolsSelection: state => state.toolsSelection,
    actionTimelineCopy: state => state.actionTimelineCopy,
    actionTimelineExport: state => state.actionTimelineExport,
    caseSensitive: state => state.caseSensitive,
    hideCards: state => state.hideCards,

    pagesDisabled: state => state.pagesDisabled,
    resolutionDisabled: state => state.resolutionDisabled,
    zoomDisabled: state => state.zoomDisabled,
    viewMenuDisabled: state => state.viewMenuDisabled,
    csvDownloadDisabled: state => state.csvDownloadDisabled,
    isMarkupEnabled: state => mtype => !state.markupDisabled.includes(mtype),

    copyOrExportCompleted: state => state.copyOrExportCompleted,
    leftScrollBeforeCopyOrExport: state => state.leftScrollBeforeCopyOrExport,

    smallViewFavicons: state => state.smallViewFavicons,
};

const actions = {
    setResolution({ commit }, resolution) {
        commit('SET_RESOLUTION', resolution);
    },
    setTerm({ commit }, term) {
        commit('SET_TERM', term);
    },
    clearViewStates({ commit }) {
        commit('CLEAR_VIEW_STATES');
    },
    setFeatureEnabled({ commit }, feature) {
        commit('SET_FEATURE_ENABLED', feature);
    },
    setEngineCtid({ commit }, { engine, ctid }) {
        commit('SET_ENGINE_CTID', { engine, ctid });
    },
    setZoomSelection({ state, commit }, payload) {
        let zoom = payload;
        if (zoom === 'small-view' && state.zoomDisabled === 'small') {
            zoom = 'medium-view';
        }
        commit('SET_ZOOM_SELECTION', zoom);
    },
    setSelectedPages({ commit }, payload) {
        commit('SET_SELECTED_PAGES', payload);
    },
    setToolsSelection({ commit }, payload) {
        commit('SET_TOOLS_SELECTION', payload);
    },
    triggerTimelineCopy({ commit }) {
        commit('SET_ACTION_TIMELINE_COPY');
    },
    triggerTimelineExport({ commit }) {
        commit('SET_ACTION_TIMELINE_EXPORT');
    },
    setCaseSensitive({ commit }, payload) {
        commit('SET_CASE_SENSITIVE', payload);
    },
    setHideCards({ commit }, on) {
        commit('SET_HIDE_CARDS', on);
    },

    setResolutionDisabled({ commit }, payload) {
        commit('SET_RESOLUTION_DISABLED', payload);
    },
    setPagesDisabled({ commit }, payload) {
        commit('SET_PAGES_DISABLED', payload);
    },
    setZoomDisabled({ state, commit }, payload) {
        commit('SET_ZOOM_DISABLED', payload);
        if (payload === 'small' && state.zoomSelection === 'small-view') {
            commit('SET_ZOOM_SELECTION', 'medium-view');
        }
    },
    setViewMenuDisabled({ commit }, payload) {
        commit('SET_VIEW_MENU_DISABLED', payload);
    },
    setCsvDownloadDisabled({ commit }, payload) {
        commit('SET_CSV_DOWNLOAD_DISABLED', payload);
    },
    setMarkupDisabled({ commit }, typeList) {
        commit('SET_MARKUP_DISABLED', typeList);
    },
    setSmallViewFavicons({ commit }, on) {
        commit('SET_SMALL_VIEW_FAVICONS', on);
    },
    resetToolbar({ dispatch }) {
        dispatch("setResolutionDisabled", false);
        dispatch("setPagesDisabled", false);
        dispatch("setZoomDisabled", "none");
        dispatch("setViewMenuDisabled", false);
        dispatch("setCsvDownloadDisabled", false);
        dispatch("setMarkupDisabled", []);
    },
    setCopyOrExportCompleted({ commit }) {
        commit('SET_COPY_OR_EXPORT_COMPLETED');
    },
    setLeftScrollBeforeCopyOrExport({ commit }, payload) {
        commit('SET_LEFT_SCROLL_BEFORE_COPY_OR_EXPORT', payload);
    },
}

const mutations = {
    SET_RESOLUTION(state, resolution) {
        const _resolution = { ...resolution }
        if (!_resolution.custom) {
            _resolution.customOpen = false;
            _resolution.custom = false;
        }
        state.resolution = _resolution;

        if (_resolution.columns) {
            storage.set("columns", _resolution.columns);
            storage.set("skip", -1);
        } else {
            storage.set("columns", "");
            storage.set("skip", storage.daysToSkipAdapter(_resolution.days));
        }

    },
    SET_TERM(state, term) {
        state.term = term;
    },
    CLEAR_VIEW_STATES(state) {
        state.viewStates = new ViewStates();
    },
    SET_FEATURE_ENABLED(state, feature) {
        state.viewStates[feature.key].enabled = true;
    },
    SET_ENGINE_CTID(state, { engine, ctid }) {
        state.viewStates[engine.key].ctid = ctid;
    },
    SET_ZOOM_SELECTION(state, payload) {
        state.zoomSelection = payload;
        storage.set("size", payload);
    },
    SET_SELECTED_PAGES(state, payload) {
        state.selectedPages = payload;
        storage.set("show_p1", Object.values(payload).includes(1) ? 1 : 0);
        storage.set("show_p2", Object.values(payload).includes(2) ? 1 : 0);
    },
    SET_TOOLS_SELECTION(state, payload) {
        state.toolsSelection = payload
    },
    SET_ACTION_TIMELINE_COPY(state) {
        state.actionTimelineCopy++;
    },
    SET_ACTION_TIMELINE_EXPORT(state) {
        state.actionTimelineExport++;
    },
    SET_CASE_SENSITIVE(state, payload) {
        state.caseSensitive = payload;
    },
    SET_HIDE_CARDS(state, on) {
        state.hideCards = on;
    },
    SET_RESOLUTION_DISABLED(state, payload) {
        state.resolutionDisabled = (payload == null ? true : payload);
    },
    SET_PAGES_DISABLED(state, payload) {
        state.pagesDisabled = (payload == null ? true : payload);
    },
    SET_ZOOM_DISABLED(state, payload) {
        state.zoomDisabled = (payload == null ? 'all' : payload);
    },
    SET_VIEW_MENU_DISABLED(state, payload) {
        state.viewMenuDisabled = (payload == null ? true : payload);
    },
    SET_CSV_DOWNLOAD_DISABLED(state, payload) {
        state.csvDownloadDisabled = (payload == null ? true : payload);
    },
    SET_MARKUP_DISABLED(state, typeList) {
        state.markupDisabled = (typeList == null ? ['rating', 'ownership', 'authority'] : typeList);
    },
    SET_COPY_OR_EXPORT_COMPLETED(state) {
        state.copyOrExportCompleted++;
    },
    SET_LEFT_SCROLL_BEFORE_COPY_OR_EXPORT(state, payload) {
        state.leftScrollBeforeCopyOrExport = payload;
    },
    SET_SMALL_VIEW_FAVICONS(state, on) {
        state.smallViewFavicons = on;  
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}