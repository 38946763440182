<template>
  <template v-if="isLegacyImageContainer">
    <highlight-result
      :element-data="elementData"
      :class="zoomSelection"
      class="card d-flex flex-row justify-content-evenly"
    >
      <template v-if="zoomSelection === 'small-view'">
        <small-view-result icon-name="images" />
      </template>
      <template v-else>
        <img
          v-for="(image, index) in elementData.imagecontainer?.image?.filter((item) =>
            validImage(item.url)
          )"
          :key="elementData.position + index"
          class="imagebox-item"
          :src="image.url"
        />
      </template>
    </highlight-result>
  </template>
  <template v-else>
    <container-wrapper
      class="container-images"
      v-slot="slotProps"
      :element-key="elementKey"
      :element-data="elementData"
      display-name="Images"
      icon-name="images"
      :show-images-on-close="true">
      <div
        v-for="(item, index) in elementData.imagecontainer?.image"
        :key="slotProps.accordionKey + index"
        class="accordion-item"
      >
        <highlight-result :element-data="item" :class="getMarkupClasses(item)">
          <div
            class="accordion-header markup-container markup-container-with-image"
            :id="'heading' + slotProps.accordionKey + index"
          >
            <div class="outer-image-wrapper">
              <div class="image-wrapper">
                <img :src="item?.desc" />
              </div>
            </div>
            <div>
              <span class="card-title ellipsis">{{ title(item) }}</span>
              <small class="card-title ellipsis">{{ subtitle(item) }}</small>
              <markup-link
                :resultData="formattedResultData(item)"
                :datetime="elementData.card.datetime"
                :resultType="item?.resulttype"
              />
            </div>
          </div>
        </highlight-result>
      </div>
    </container-wrapper>
  </template>
</template>

<script>
import ContainerWrapper from "@/components/card/ContainerWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import SmallViewResult from "@/components/card/SmallViewResult.vue";
import { mapGetters } from "vuex";

export default {
  name: "ImageContainer",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
    SmallViewResult,
  },
  computed: {
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),
    isLegacyImageContainer() {
      const imageContainer = this.elementData.imagecontainer;
      if (Array.isArray(imageContainer?.image)) {
        return imageContainer.image.every((item) => item.title === undefined);
      }
      return false;
    },
    decodedTitleObject() {
      return (item) => this.decodedTitle(item?.title) || {};
    },
    title() {
      return (item) => this.decodedTitleObject(item).title || "";
    },
    subtitle() {
      return (item) =>
        (this.decodedTitleObject(item).sub_title && this.decodedTitleObject(item).sub_title.text) ||
        "";
    },
  },
  methods: {
    validImage(url) {
      return url && url.length > 100 ? true : false;
    },
    decodedTitle(title) {
      if (typeof title === "string" && title.trim().startsWith("{") && title.trim().endsWith("}")) {
        try {
          return JSON.parse(title);
        } catch (error) {
          console.error("Error decoding JSON:", error);
          return null;
        }
      } else return title;
    },

    formattedResultData(resultData) {
      const modifiedResultData = { ...resultData };

      if (modifiedResultData && modifiedResultData.title) {
        modifiedResultData.title = this.decodedTitle(modifiedResultData.title);
        const decodedTitle = modifiedResultData.title;
        if (decodedTitle && decodedTitle.title && decodedTitle.sub_title) {
          modifiedResultData.title = `${decodedTitle.title} / ${decodedTitle.sub_title.text}`;
        }
      }
      return modifiedResultData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";

*,
:slotted(*) {
  &.small-view {
    .card {
      width: var(--timeline-card-width-small);
      margin: var(--timeline-result-margin-small);
    }
  }
}

.card {
  img {
    max-height: 50px;
    margin: 0.5px;
    max-width: 18%;
    border-radius: 2px;
  }
}
.card {
  &.regular-view {
    img {
      max-height: 50px;
      margin: 0.5px;
      max-width: 18%;
      border-radius: 2px;
    }
  }
  &.medium-view {
    .outer-image-wrapper {
      margin-left: -5px;
    }
    img {
      max-height: 50px;
      margin: 0.5px;
      max-width: 26%;
      border-radius: 2px;
    }
  }
}
.container-images {
  overflow: hidden;
} 
</style>
