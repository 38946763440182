<template>
  <result-wrapper :element-data="elementData">
    <div class="markup-container">
      <span class="card-title ellipsis">
        <!-- <favicon 
          v-if="elementData?.standard?.url && zoomSelection === 'regular-view'" 
          :url="elementData.standard.url" 
          class="standard-result-favicon" 
        /> -->

      <span class="result-title" v-html="elementData.standard.title"></span>
      </span>
      <div class="ellipsis d-flex">
        <span class="me-1" v-if="'starrating' in elementData.standard">
          {{ formatRating }}
          <i class="bi bi-star-fill xs"></i>
        </span>
        <markup-link :resultData="elementData.standard" :datetime="elementData.card.datetime"
          :resultType="elementData.resulttype" />
      </div>
    </div>
    <img v-if="elementData.standard.img" class="ms-3 rounded" :src=elementData.standard.img alt="">
  </result-wrapper>
</template>

<script>
import MarkupLink from "@/components/card/MarkupLink.vue";
import ResultWrapper from "@/components/card/ResultWrapper.vue";
import commonUtil from "@/utils/commonUtil.js";
// import Favicon from "@/components/Favicon.vue";
import { mapGetters } from "vuex";

export default {
  name: "Standard",
  props: {
    elementData: Object,
  },
  components: {
    MarkupLink,
    ResultWrapper,
    // Favicon
  },
  data() {
    return {
    }
  },

  computed: {
    formatRating() {
      return commonUtil.formattedRating(this.elementData.standard.starrating.text, this.elementData.standard.starrating.value);
    },
    ...mapGetters("toolbar", {
      zoomSelection: "zoomSelection",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/_icons.scss";


.card {

  .card-body {
    .fa-twitter {
      position: absolute;
      top: 3px;
      right: 4px;
    }
  }
}

.standard-result-favicon {
  margin-right: 0.25rem;
  display: inline-block;
  vertical-align: middle;
}

.result-title {
  vertical-align: middle;
}

.xs {
  font-size: .75em;
  color: #FCD663;
  text-shadow: 0 0 2px #000;
}
</style>
